import React, { createContext, useState } from 'react';

export const ContactContext = createContext();

export const ContactProvider = ({ children }) => {
    const [selectedSubject, setSelectedSubject] = useState('');

    return (
        <ContactContext.Provider value={{ selectedSubject, setSelectedSubject }}>
            {children}
        </ContactContext.Provider>
    );
};
