import React from 'react';
import { useNavigate } from 'react-router-dom';

const NoMatch = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate(-1);
  }, [navigate]);

  return null;
};

export default NoMatch;
