import React, { useContext, useEffect, useState } from 'react';
import './Accueil.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHammer, faSnowflake, faBrush, faScrewdriver, faAnchor } from '@fortawesome/free-solid-svg-icons';
import Background from '../Images/Background.webp';
import { ContactContext } from '../Contact/ContactContext';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Accueil({ onLinkClick }) {
  const { setSelectedSubject } = useContext(ContactContext);
  const [isSliderReady, setIsSliderReady] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleLearnMoreClick = (subject) => {
    setSelectedSubject(subject);
    onLinkClick();
  };

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    rtl: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1
        }
      }
    ],
    beforeChange: () => setIsSliderReady(true),
    afterChange: () => setIsSliderReady(true),
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => setIsSliderReady(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className='background-home'>
      <div className='container-home-1'>
        <img src={Background} alt="Background" />
        <div className='container-text'>
          <h2>À votre service depuis 1976</h2>
          <h3>Bienvenue</h3>
          <h1>Chantier naval Casanova</h1>
        </div>
      </div>
      <div className='container-home-2'>
        {windowWidth <= 1440 ? (
          isSliderReady && (
            <Slider {...settings}>
              <div className='box'>
                <div className='container-icon'>
                  <FontAwesomeIcon className='icon' icon={faHammer} />
                </div>
                <div className='container-title'>
                  <h4>Construction et Rénovation</h4>
                </div>
                <div className='container-description'>
                  <p>Modernisez votre bateau avec nos<br />services professionnels.</p>
                </div>
                <div className='container-btn'>
                  <button onClick={() => handleLearnMoreClick("Construction et Rénovation")}>Contactez-nous</button>
                </div>
              </div>
              <div className='box'>
                <div className='container-icon'>
                  <FontAwesomeIcon className='icon' icon={faSnowflake} />
                </div>
                <div className='container-title'>
                  <h4>Hivernage</h4>
                </div>
                <div className='container-description'>
                  <p>Protégez efficacement votre navire<br />durant la saison hivernale.</p>
                </div>
                <div className='container-btn'>
                  <button onClick={() => handleLearnMoreClick("Hivernage")}>Contactez-nous</button>
                </div>
              </div>
              <div className='box'>
                <div className='container-icon'>
                  <FontAwesomeIcon className='icon' icon={faBrush} />
                </div>
                <div className='container-title'>
                  <h4>Peinture et Vernis</h4>
                </div>
                <div className='container-description'>
                  <p>Appliquez une nouvelle couche de<br />peinture et protégez votre coque.</p>
                </div>
                <div className='container-btn'>
                  <button onClick={() => handleLearnMoreClick("Peinture et Vernis")}>Contactez-nous</button>
                </div>
              </div>
              <div className='box'>
                <div className='container-icon'>
                  <FontAwesomeIcon className='icon' icon={faScrewdriver} />
                </div>
                <div className='container-title'>
                  <h4>Spécialiste bois et Composite</h4>
                </div>
                <div className='container-description'>
                  <p>Expertise en réparations et rénovations<br />de matériaux bois et composite.</p>
                </div>
                <div className='container-btn'>
                  <button onClick={() => handleLearnMoreClick("Spécialiste bois et Composite")}>Contactez-nous</button>
                </div>
              </div>
            </Slider>
          )
        ) : (
          <>
            <div className='box'>
              <div className='container-icon'>
                <FontAwesomeIcon className='icon' icon={faHammer} />
              </div>
              <div className='container-title'>
                <h4>Construction et Rénovation</h4>
              </div>
              <div className='container-description'>
                <p>Modernisez votre bateau avec nos<br />services professionnels.</p>
              </div>
              <div className='container-btn'>
                <button onClick={() => handleLearnMoreClick("Construction et Rénovation")}>Contactez-nous</button>
              </div>
            </div>
            <div className='box'>
              <div className='container-icon'>
                <FontAwesomeIcon className='icon' icon={faSnowflake} />
              </div>
              <div className='container-title'>
                <h4>Hivernage</h4>
              </div>
              <div className='container-description'>
                <p>Protégez efficacement votre navire<br />durant la saison hivernale.</p>
              </div>
              <div className='container-btn'>
                <button onClick={() => handleLearnMoreClick("Hivernage")}>Contactez-nous</button>
              </div>
            </div>
            <div className='box'>
              <div className='container-icon'>
                <FontAwesomeIcon className='icon' icon={faBrush} />
              </div>
              <div className='container-title'>
                <h4>Peinture et Vernis</h4>
              </div>
              <div className='container-description'>
                <p>Appliquez une nouvelle couche de<br />peinture et protégez votre coque.</p>
              </div>
              <div className='container-btn'>
                <button onClick={() => handleLearnMoreClick("Peinture et Vernis")}>Contactez-nous</button>
              </div>
            </div>
            <div className='box'>
              <div className='container-icon'>
                <FontAwesomeIcon className='icon' icon={faScrewdriver} />
              </div>
              <div className='container-title'>
                <h4>Spécialiste bois et Composite</h4>
              </div>
              <div className='container-description'>
                <p>Expertise en réparations et rénovations<br />de matériaux bois et composite.</p>
              </div>
              <div className='container-btn'>
                <button onClick={() => handleLearnMoreClick("Spécialiste bois et Composite")}>Contactez-nous</button>
              </div>
            </div>
          </>
        )}
      </div>
      <div className='container-home-3'>
        <FontAwesomeIcon icon={faAnchor} />
      </div>
    </div>
  );
}

export default Accueil;
