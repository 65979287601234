import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import Logo from '../Images/Logo.webp';
import './Header.css';
import { ContactContext } from '../Contact/ContactContext';

function Header({ onLinkClick }) {
    const [isNavVisible, setIsNavVisible] = useState(false);
    const [isDialogVisible, setIsDialogVisible] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    const { setSelectedSubject } = useContext(ContactContext);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (isNavVisible && window.innerWidth <= 900) {
            document.body.classList.add('no-scroll-header');
        } else {
            document.body.classList.remove('no-scroll-header');
        }
    }, [isNavVisible]);

    const toggleNav = () => {
        setIsNavVisible(!isNavVisible);
    };

    const showDialog = () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
            setTimeoutId(null);
        }
        setIsDialogVisible(true);
    };

    const hideDialog = () => {
        const id = setTimeout(() => {
            setIsDialogVisible(false);
        }, 800);
        setTimeoutId(id);
    };

    const handleSubjectClick = (subject) => {
        setSelectedSubject(subject);
        if (location.pathname !== "/") {
            navigate("/", { state: { scrollToContact: true } });
        } else {
            onLinkClick();
        }
        toggleNav();
    };

    const handleContactClick = () => {
        if (location.pathname !== "/") {
            navigate("/", { state: { scrollToContact: true } });
        } else {
            onLinkClick();
        }
        toggleNav();
    };

    useEffect(() => {
        if (location.state && location.state.scrollToContact) {
            onLinkClick();
            window.history.replaceState({}, document.title); 
        }
    }, [location, onLinkClick]);

    const handleLogoClick = () => {
        navigate("/");
        setIsNavVisible(false);
    };

    return (
        <header className={location.pathname === '/histoire' ? 'header-history' : ''}>
            <ul>
                <span className='container-logo' onClick={handleLogoClick}>
                    <img src={Logo} alt="Logo" draggable="false"/>
                </span>
                <FontAwesomeIcon icon={faBars} className="menu-icon" onClick={toggleNav} />
                <div className={`container-nav ${isNavVisible ? 'visible' : ''}`}>
                    <div className='container-close-icon' onClick={toggleNav}>
                        <FontAwesomeIcon icon={faXmark} />
                    </div>
                    <li><Link className='link' to="/" onClick={toggleNav}>Accueil</Link></li>
                    <li onMouseEnter={showDialog} onMouseLeave={hideDialog}>
                        <span className='link'>Nos prestations</span>
                        {isDialogVisible && (
                            <div className='dialog-box' onMouseEnter={showDialog} onMouseLeave={hideDialog}>
                                <p onClick={() => handleSubjectClick("Spécialiste bois et Composite")}>Spécialiste bois et Composite</p>
                                <p onClick={() => handleSubjectClick("Construction")}>Construction</p>
                                <p onClick={() => handleSubjectClick("Réparation")}>Réparation</p>
                                <p onClick={() => handleSubjectClick("Restauration")}>Restauration</p>
                                <p onClick={() => handleSubjectClick("Peinture et Vernis")}>Peinture et Vernis</p>
                                <p onClick={() => handleSubjectClick("Carénage")}>Carénage</p>
                                <p onClick={() => handleSubjectClick("Hivernage")}>Hivernage</p>
                                <p onClick={() => handleSubjectClick("Entretien Courant")}>Entretien Courant</p>
                                <p onClick={() => handleSubjectClick("Modification")}>Modification</p>
                            </div>
                        )}
                    </li>
                    <li><Link className='link' to="/histoire" onClick={toggleNav}>Notre histoire</Link></li>
                    <li><span className='link' onClick={handleContactClick}>Contactez-nous</span></li>
                </div>
            </ul>
        </header>
    );
}

export default Header;
