import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import './Legal.css';

function Legal() {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleBackClick = () => {
        navigate('/');
    };

    return (
        <div className='background-legal'>
            <div className='container-legal'>
                <div className='container-back'>
                    <FontAwesomeIcon className='back-icon' onClick={handleBackClick} icon={faArrowLeft} />
                </div>
                <h1>Mentions légales</h1>

                <h2>Identité de l'éditeur</h2>
                <p><strong>Nom ou Raison sociale :</strong> Chantier Casanova</p>
                <p><strong>Adresse :</strong> Chemin de la paix 10, 1803 Chardonne, Suisse</p>
                <p><strong>Téléphone :</strong> +41 79 888 79 17</p>
                <p><strong>Email :</strong> info@chantiercasanova.ch</p>
                <p><strong>Responsable de la publication :</strong> Tom Casanova</p>

                <h2>Hébergement</h2>
                <p><strong>Nom de l'hébergeur :</strong> Infomaniak.ch</p>
                <p><strong>Adresse de l'hébergeur :</strong> Rue Eugène-Marziano 25, 1227 Genève</p>
                <p><strong>Téléphone :</strong> +41 22 820 35 40</p>

                <h2>Identification de l'entreprise</h2>
                <p><strong>Numéro d'immatriculation (IDE) :</strong> CHE-261.733.191</p>
                <p><strong>Numéro CH-ID :</strong> CH-550-1203086-6</p>
                <p><strong>Numéro OFRC-ID :</strong> 1537088</p>
                <p><strong>Forme juridique :</strong> Société à responsabilité limitée (SARL)</p>

                <h2>Conditions générales d'utilisation (CGU)</h2>
                <p>Le site Chantier Casanova est un site vitrine dont l'objectif est de fournir des informations sur l’ensemble des activités de la société. L'utilisation du site implique l'acceptation pleine et entière des conditions générales d'utilisation décrites ci-après.</p>

                <h2>Politique de confidentialité</h2>
                <p>Chantier Casanova ne collecte des données personnelles que lorsque l'utilisateur utilise le formulaire de contact pour envoyer un message. Ces données, comprenant le nom, l'email, le numéro de téléphone et le message de l'utilisateur, sont uniquement utilisées pour répondre à la demande de contact. Aucune donnée n'est stockée dans une base de données, et les informations ne sont pas partagées avec des tiers. Le site ne vend pas de produits directement et ne collecte aucune donnée financière.</p>

                <h2>Limitation de responsabilité</h2>
                <p>Chantier Casanova ne saurait être tenu responsable des dommages directs ou indirects causés au matériel de l’utilisateur lors de l’accès au site chantiercasanova.ch, ni des dommages résultant de l'utilisation d'informations présentes sur le site ou sur des sites externes liés.</p>

                <h2>Loi applicable et juridiction compétente</h2>
                <p>Les présentes mentions légales sont régies par la loi suisse. En cas de litige, les tribunaux suisses seront seuls compétents.</p>

                <h3>© 2024 Réalisé par <a href="https://www.logixcube.ch/" target="_blank" rel="noopener noreferrer">Logixcube</a></h3>
            </div>
        </div>
    );
}

export default Legal;
