import React, { useContext, useEffect, useState } from 'react';
import './Contact.css';
import Boat from '../Images/Contact.webp';
import { ContactContext } from './ContactContext';
import Alert from '../Alert/Alert';

function Contact() {
  const { selectedSubject } = useContext(ContactContext);
  const [formData, setFormData] = useState({
    lastName: '',
    firstName: '',
    email: '',
    phone: '',
    subject: '',
    comment: ''
  });

  const [alert, setAlert] = useState({ show: false, message: '', type: '' });
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    const loadReCaptcha = () => {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=6Lf5qhQqAAAAAMOToCH4yXPfR6EKo_PTzZNeHtAl`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    };

    loadReCaptcha();
  }, []);

  useEffect(() => {
    setFormData(prevFormData => ({
      ...prevFormData,
      subject: selectedSubject
    }));
  }, [selectedSubject]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [id]: value
    }));
  };

  const handleKeyPress = (e) => {
    const charCode = e.charCode;
    const charStr = String.fromCharCode(charCode);

    if (!/[0-9+\s]/.test(charStr)) {
      e.preventDefault();
      return;
    }

    if (charStr === ' ' && e.target.value[e.target.value.length - 1] === ' ') {
      e.preventDefault();
      return;
    }
  };

  const sanitizeInput = (input) => {
    return input.replace(/[<>/'"\\;]/g, '');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    window.grecaptcha.ready(() => {
      window.grecaptcha.execute('6Lf5qhQqAAAAAMOToCH4yXPfR6EKo_PTzZNeHtAl', { action: 'submit' }).then((token) => {
        const sanitizedData = {
          lastName: sanitizeInput(formData.lastName.trim()),
          firstName: sanitizeInput(formData.firstName.trim()),
          email: sanitizeInput(formData.email.trim()),
          phone: sanitizeInput(formData.phone.trim()),
          subject: sanitizeInput(formData.subject.trim()),
          comment: sanitizeInput(formData.comment.trim()),
          recaptchaToken: token
        };

        if (!sanitizedData.lastName || !sanitizedData.firstName || !sanitizedData.email || !sanitizedData.phone || !sanitizedData.subject || !sanitizedData.comment) {
          setAlert({ show: true, message: 'Veuillez remplir tous les champs du formulaire.', type: 'error' });
          return;
        }

        setIsButtonDisabled(true);

        const url = 'https://www.chantiercasanova.ch/casanova/src/Casanova/Backend/Contact.php'; 

        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(sanitizedData),
        })
        .then(response => {
          if (!response.ok) {
            throw new Error(`La réponse du réseau n'était pas correcte: ${response.statusText}`);
          }
          return response.json();
        })
        .then(data => {
          if (data.message === 'Formulaire envoyé avec succès.') {
            setAlert({ show: true, message: 'Formulaire envoyé avec succès.', type: 'success' });
          } else {
            setAlert({ show: true, message: 'Erreur lors de l\'envoi du formulaire.', type: 'error' });
          }
          setTimeout(() => setIsButtonDisabled(false), 30000);
        })
        .catch(error => {
          setAlert({ show: true, message: 'Erreur lors de l\'envoi du formulaire.', type: 'error' });
          console.error('Erreur:', error);
          setTimeout(() => setIsButtonDisabled(false), 30000);
        });
      });
    });
  };

  return (
    <div className='container-contact'>
      {alert.show && (
        <Alert 
          message={alert.message} 
          type={alert.type} 
          onClose={() => setAlert({ ...alert, show: false })} 
        />
      )}
      <div className='container-left'>
        <h1>Contactez-nous</h1>
        <p>Nous sommes à votre disposition pour<br />toute demande de renseignements ou de services.</p>
        <form onSubmit={handleSubmit}>
          <div className='container-1'>
            <div className='input-box'>
              <label htmlFor="lastName">Nom</label>
              <input type="text" id="lastName" value={formData.lastName} onChange={handleChange} />
            </div>
            <div className='input-box'>
              <label htmlFor="firstName">Prénom</label>
              <input type="text" id="firstName" value={formData.firstName} onChange={handleChange} />
            </div>
          </div>
          <div className='container-2'>
            <div className='input-box'>
              <label htmlFor="email">E-mail</label>
              <input type="email" id="email" value={formData.email} onChange={handleChange} />
            </div>
            <div className='input-box'>
              <label htmlFor="phone">Numéro de téléphone</label>
              <input type="text" id="phone" value={formData.phone} onChange={handleChange} onKeyPress={handleKeyPress} />
            </div>
          </div>
          <div className='container-3'>
            <div className='input-box'>
              <label htmlFor="subject">Objet</label>
              <select id="subject" value={formData.subject} onChange={handleChange}>
                <option value="">Sélectionnez un objet</option>
                <option value="Spécialiste bois et Composite">Spécialiste bois et composite</option>
                <option value="Construction">Construction</option>
                <option value="Construction et Rénovation">Construction et Rénovation</option>
                <option value="Réparation">Réparation</option>
                <option value="Restauration">Restauration</option>
                <option value="Peinture et Vernis">Peinture et Vernis</option>
                <option value="Carénage">Carénage</option>
                <option value="Hivernage">Hivernage</option>
                <option value="Entretien Courant">Entretien Courant</option>
                <option value="Modification">Modification</option>
                <option value="Autre">Autre</option>
              </select>
            </div>
            <div className='input-box'>
              <label htmlFor="comment">Commentaire</label>
              <textarea id="comment" value={formData.comment} onChange={handleChange}></textarea>
            </div>
          </div>
          <div className='container-btn'>
            <button type="submit" disabled={isButtonDisabled}>Envoyer</button>
          </div>
        </form>
      </div>
      <div className='container-right'>
        <img src={Boat} alt="Contact" />
      </div>
    </div>
  );
}

export default Contact;
