import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './Alert.css';

const Alert = ({ message, type, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 5000);

    return () => clearTimeout(timer); 
  }, [onClose]);

  return ReactDOM.createPortal(
    <div className={`alert-message ${type}`}>
      <h4>{type === 'error' ? "Message d'erreur" : 'Message de succès'}</h4>
      <p>{message}</p>
    </div>,
    document.body
  );
};

export default Alert;
