import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { Typewriter } from 'react-simple-typewriter';
import './History.css';
import History_1 from '../Images/History-1.webp';
import History_2 from '../Images/History-2.webp';
import History_3 from '../Images/History-3.webp';
import History_4 from '../Images/History-4.webp';
import History_5 from '../Images/History-5.webp';
import History_6 from '../Images/History-6.webp';
import History_7 from '../Images/History-7.webp';
import History_8 from '../Images/History-8.webp';
import History_9 from '../Images/History-9.webp';
import History_10 from '../Images/History-10.webp';
import History_11 from '../Images/History-11.webp';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Histoire() {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <div className="background-history">
            <div className='container-history-1'>
                <div className='container-img'>
                    <img src={History_1} alt="" />
                </div>
                <div className='container-text'>
                    <h1>Notre Histoire</h1>
                    <p>
                        {windowSize.width >= 1440 ? (
                            <Typewriter
                                words={[
                                    "Fondé en 1976 par Helmut Casanova, notre chantier naval familial, situé au pittoresque Port de la Pichette, au pied du splendide Lavaux, est bien plus qu'un simple chantier naval. C'est un héritage vivant où la passion pour l'artisanat nautique et l'expertise technique se transmettent de génération en génération. Depuis ses débuts modestes, Helmut Casanova a posé les fondations d'une entreprise qui, sous la direction de son fils Marcel pendant près de 30 ans, a intégré des technologies modernes tout en maintenant les plus hauts standards de qualité. En 2022, Tom Casanova, représentant la troisième génération, a pris les rênes avec une vision innovante, alliant tradition et modernité pour offrir des services exceptionnels de construction, rénovation et entretien de navires. Le Port de la Pichette, entouré de paysages à couper le souffle, nous inspire chaque jour à préserver l'environnement marin. Rejoignez-nous dans cette aventure maritime et découvrez comment nous pouvons transformer vos rêves nautiques en réalité, en respectant les règles de la construction naval."
                                ]}
                                loop={1}
                                typeSpeed={30}
                                deleteSpeed={0}
                                delaySpeed={5000}
                            />
                        ) : (
                            "Fondé en 1976 par Helmut Casanova, notre chantier naval familial, situé au pittoresque Port de la Pichette, au pied du splendide Lavaux, est bien plus qu'un simple chantier naval. C'est un héritage vivant où la passion pour l'artisanat nautique et l'expertise technique se transmettent de génération en génération. Depuis ses débuts modestes, Helmut Casanova a posé les fondations d'une entreprise qui, sous la direction de son fils Marcel pendant près de 30 ans, a intégré des technologies modernes tout en maintenant les plus hauts standards de qualité. En 2022, Tom Casanova, représentant la troisième génération, a pris les rênes avec une vision innovante, alliant tradition et modernité pour offrir des services exceptionnels de construction, rénovation et entretien de navires. Le Port de la Pichette, entouré de paysages à couper le souffle, nous inspire chaque jour à préserver l'environnement marin. Rejoignez-nous dans cette aventure maritime et découvrez comment nous pouvons transformer vos rêves nautiques en réalité, en respectant les règles de la construction naval."
                        )}
                    </p>
                </div>
            </div>
            <div className='container-history-2'>
                <h2>Quelques aperçus de nos réalisations</h2>
                <Slider {...settings} className='container-img'>
                    <div>
                        <img src={History_2} alt="" />
                    </div>
                    <div>
                        <img src={History_3} alt="" />
                    </div>
                    <div>
                        <img src={History_4} alt="" />
                    </div>
                    <div>
                        <img src={History_5} alt="" />
                    </div>
                    <div>
                        <img src={History_6} alt="" />
                    </div>
                    <div>
                        <img src={History_7} alt="" />
                    </div>
                    <div>
                        <img src={History_8} alt="" />
                    </div>
                    <div>
                        <img src={History_9} alt="" />
                    </div>
                    <div>
                        <img src={History_10} alt="" />
                    </div>
                    <div>
                        <img src={History_11} alt="" />
                    </div>
                </Slider>
            </div>
        </div>
    );
}

export default Histoire;
