import React, { useRef, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './Casanova/Header/Header';
import Footer from './Casanova/Footer/Footer';
import Accueil from './Casanova/Accueil/Accueil';
import NoMatch from './Casanova/Services/NoMatch';
import Contact from './Casanova/Contact/Contact';
import { ContactProvider } from './Casanova/Contact/ContactContext';
import History from './Casanova/History/History';
import Legal from './Casanova/Legal/Legal';

function AppContent() {
    const location = useLocation();
    const contactRef = useRef(null);

    const scrollToContact = () => {
        if (contactRef.current) {
            contactRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if (location.state && location.state.scrollToContact) {
            scrollToContact();
        }
    }, [location]);

    const showFooter = location.pathname === '/' || location.pathname === '/histoire';

    return (
        <div className="App">
            <Header onLinkClick={scrollToContact} />
            <main>
                <Routes>
                    <Route path="/" element={<Accueil onLinkClick={scrollToContact} />} />
                    <Route path="/histoire" element={<History />} />
                    <Route path="/mentions" element={<Legal />} />
                    <Route path="*" element={<NoMatch />} />
                </Routes>
                {location.pathname === '/' && (
                    <div ref={contactRef}>
                        <Contact />
                    </div>
                )}
            </main>
            {showFooter && <Footer onLinkClick={scrollToContact} />}
        </div>
    );
}

function App() {
    return (
        <ContactProvider>
            <Router>
                <AppContent />
            </Router>
        </ContactProvider>
    );
}

export default App;
