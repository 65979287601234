import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Logo from '../Images/Logo.webp';
import './Footer.css';
import { ContactContext } from '../Contact/ContactContext';

function Footer({ onLinkClick }) {
    const { setSelectedSubject } = useContext(ContactContext);
    const navigate = useNavigate();
    const location = useLocation();

    const handleSubjectClick = (subject) => {
        setSelectedSubject(subject);
        if (location.pathname !== "/") {
            navigate("/", { state: { scrollToContact: true } });
        } else {
            onLinkClick();
        }
    };

    const handleMentionsClick = () => {
        navigate('/mentions');
    };

    return (
        <footer>
            <div className='container-top'>
                <div className='box'>
                    <span className='container-logo'>
                        <img src={Logo} alt="Logo" draggable="false" />
                    </span>
                    <p>
                        Depuis 1976, Casanova transforme vos visions maritimes en réalité. Construction, rénovation
                        et entretien de bateaux avec qualité et passion.
                    </p>
                </div>
                <div className='box'>
                    <h1>Nos Prestations</h1>
                    <span onClick={() => handleSubjectClick("Spécialiste bois et Composite")}>Spécialiste bois et Composite</span>
                    <span onClick={() => handleSubjectClick("Construction")}>Construction</span>
                    <span onClick={() => handleSubjectClick("Réparation")}>Réparation</span>
                    <span onClick={() => handleSubjectClick("Restauration")}>Restauration</span>
                    <span onClick={() => handleSubjectClick("Peinture et Vernis")}>Peinture et Vernis</span>
                    <span onClick={() => handleSubjectClick("Carénage")}>Carénage</span>
                    <span onClick={() => handleSubjectClick("Hivernage")}>Hivernage</span>
                    <span onClick={() => handleSubjectClick("Entretien Courant")}>Entretien Courant</span>
                    <span onClick={() => handleSubjectClick("Modification")}>Modification</span>
                </div>
                <div className='box'>
                    <h1>Nos horaires</h1>
                    <div className='container-hours'>
                        <span>Lun-Ven :</span>
                        <div className='hours'>
                            <span>7h00 - 12h00</span>
                            <span>13h30 - 17h00</span>
                        </div>
                    </div>
                    <div className='container-hours'>
                        <span>Samedi :</span>
                        <div className='hours'>
                            <span>Fermé</span>
                        </div>
                    </div>
                    <div className='container-hours'>
                        <span>Dimanche :</span>
                        <div className='hours'>
                            <span>Fermé</span>
                        </div>
                    </div>
                </div>
                <div className='box'>
                    <h1>Nos coordonnées</h1>
                    <div>
                        <FontAwesomeIcon className='icon-contact' icon={faLocationDot} />
                        <a href="https://maps.google.com/maps?q=Chemin+de+la+paix+10,+1803+Chardonne" style={{ textDecoration: 'none', color: '#ffffff' }} target="_blank" rel="noopener noreferrer">
                            <span>Chemin de la paix 10,<br />1803 Chardonne</span>
                        </a>
                    </div>
                    <div>
                        <FontAwesomeIcon className='icon-contact' icon={faPhone} />
                        <a href="tel:+41798887917" style={{ textDecoration: 'none', color: '#ffffff' }}>
                            <span>+41 79 888 79 17</span>
                        </a>
                    </div>
                    <div>
                        <FontAwesomeIcon className='icon-contact' icon={faEnvelope} />
                        <a href="mailto:info@chantiercasanova.ch" style={{ textDecoration: 'none', color: '#ffffff' }}>
                            <span>info@chantiercasanova.ch</span>
                        </a>
                    </div>
                </div>
            </div>
            <div className='container-center'>
                <h6 onClick={handleMentionsClick} style={{ cursor: 'pointer' }}>Mentions légales</h6>
                <h5>© 2024 tout droit réservé à Casanova.</h5>
            </div>
            <div className='container-bottom'>
                Conçu par <a href="https://www.logixcube.ch/" style={{ textDecoration: 'none', color: '#ffffff' }} target="_blank" rel="noopener noreferrer">Logixcube.ch</a>
            </div>
        </footer>
    );
}

export default Footer;
